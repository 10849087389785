"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _reactGa = _interopRequireDefault(require("react-ga4"));
// GA pageview and events tracking
// https://builds.atlassian.net/browse/SUB-182
// https://builds.atlassian.net/browse/SUB-185

if (window.buildsite.config.ga_tracking_id || window.buildsite.config.g_ads_tracking_id) {
  let trackers = [];
  if (window.buildsite.config.ga_tracking_id) trackers.push({
    trackingId: window.buildsite.config.ga_tracking_id,
    gaOptions: {
      groups: "GoogleAnalytics"
    }
  });
  if (window.buildsite.config.g_ads_tracking_id) trackers.push({
    trackingId: window.buildsite.config.g_ads_tracking_id,
    gaOptions: {
      groups: "GoogleAds"
    }
  });
  $(function () {
    _reactGa.default.initialize(trackers, {
      legacyDimensionMetric: false
    });
  });
}
function ga_call(opt, cb) {
  const uid = () => {
    return buildsite.user().data && buildsite.user().data.user_id;
  };
  const rGA = opts => {
    if (!_.isEmpty(opts)) _reactGa.default.set(opts); //set page and user info for events being fired idetificate too
    cb();
  };
  if (!buildsite || 'function' !== typeof buildsite.user) {
    return rGA(opt);
  }
  const ga_set = _.assign({}, opt);
  if (uid()) {
    ga_set.userId = uid();
    rGA(ga_set);
  } else {
    buildsite.user().open().always(function () {
      if (uid()) ga_set.userId = uid();
      rGA(ga_set);
    });
  }
}
function logPageView(custom_page) {
  let pg = custom_page || window.location.pathname;
  // https://builds.atlassian.net/browse/SUB-218
  if (/\/ro/.test(pg)) {
    pg = pg.replace(/\/[0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12}$/, ''); // remove invite code from last path part if ends with uuid4
  }

  ga_call({
    page: pg
  }, function () {
    _reactGa.default.send({
      hitType: 'pageview',
      page: pg,
      send_to: 'GoogleAnalytics'
    }); //https://github.com/PriceRunner/react-ga4#reactgapageviewpath-_-title
  });
}

function logEvent(e) {
  let name = e.name || `${e.category}:${e.action}`;
  ga_call({}, function () {
    _reactGa.default.event(name, {
      ..._.omit(e, ['name']),
      send_to: 'GoogleAnalytics'
    });
  });
}

//only to google ads which must be specified at 'send_to' event parameter
function logGAds(type, data) {
  _reactGa.default.event(type, data);
}
var _default = {
  logPageView,
  logEvent,
  logGAds
};
exports.default = _default;