"use strict";

/* eslint-disable indent */

function isDeleted(o) {
  if (!o) return false;
  return o.status && (o.status == "deleted" || o.status == "gone");
}
function is_user_registered(u) {
  let us = u && u.status;
  if (!us) return false;
  return ["ghost", "initial"].indexOf(us) == -1;
}
function capitalize(s) {
  return typeof s !== 'string' ? '' : s.charAt(0).toUpperCase() + s.slice(1);
}
function prettyName(name, email) {
  let pName = null;
  if (name) {
    pName = name;
    if (email) {
      pName += ` (${email})`;
    }
  } else if (email) {
    pName = email;
  }
  return pName;
}

/* source: api/controllers/api/0.3/base.js */
function cleanPath(path, nojoin) {
  let cp = path.split("/").map(p => p.replace(/#.*$/g, ''));
  return nojoin ? cp : cp.join('/');
}
function escapeTags(text) {
  if (!text) return text;
  return text.replace(/</g, '&lt;').replace(/>/g, '&gt;');
}

// https://builds.atlassian.net/browse/SUB-401
// https://stackoverflow.com/a/8943487
function linkify(text, editable) {
  if (!text) return text;
  let urlRegex = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
  return text.replace(urlRegex, url => {
    return editable ? '<a href="#" style="cursor: pointer;" onClick="window.open(\'' + url + '\',\'_blank\')">' + url + '</a>' : '<a target="_blank" href="' + url + '">' + url + '</a>';
  });
}
module.exports = {
  isDeleted,
  is_user_registered,
  capitalize,
  prettyName,
  cleanPath,
  escapeTags,
  linkify
};